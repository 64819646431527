import * as Sentry from "@sentry/angular";
import { environment } from "./environments/environment";

import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { mergeApplicationConfig } from "@angular/core";
import { appBrowserConfig } from "./app/app.config.browser";
import { sharedAppConfig } from "./app/app.config.shared";
import { Environment } from "@libs/shares/models/models";
import "@libs/shares/utils/bigint.utils";

const buildVersion = BUILD_VERSION;
const sentryEnvironmentName =
  environment.name === Environment.DEVELOPMENT
    ? Environment.DEVELOPMENT
    : window.location.hostname === "portal-staging.1inch.dev"
      ? Environment.STAGING
      : Environment.PRODUCTION;

Sentry.init({
  dsn: "https://b804d7b08c117968276b1a0ef0e09ea4@o4506234207928320.ingest.sentry.io/4506234378846208",
  release: buildVersion,
  enabled: environment.name !== Environment.DEVELOPMENT,
  environment: sentryEnvironmentName,
  integrations: [
    // Registers and configures the tracing integration
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      unblock: ["[data-sentry-unblock]"],
      unmask: ["[data-sentry-unmask]"],
      networkDetailAllowUrls: ["/assets/docs/"],
      beforeAddRecordingEvent: (event) => {
        // Do not capture fetch/xhr requests, unless the response code is 400+
        if (
          event.data.tag === "performanceSpan" &&
          (event.data.payload.op === "resource.fetch" || event.data.payload.op === "resource.xhr") &&
          event.data.payload.data?.statusCode >= 400
        ) {
          return null;
        }

        return event;
      }
    })
  ],

  enableTracing: true,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [/Failed to refresh token/]
});

Sentry.setTags({
  buildVersion: buildVersion
});

bootstrapApplication(AppComponent, mergeApplicationConfig(appBrowserConfig, sharedAppConfig)).catch((err) =>
  console.error(err)
);
