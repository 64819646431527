import { Route } from "@angular/router";
import { canAccess } from "./domain/auth/auth.guard";
import { loginGuard } from "./feature/login/login.guard";
import { profileSetupGuard } from "./feature/profile-set-up/profile-set-up.guard";
import { consentGiven } from "./domain/legal/consent.guard";
import { isSuspended } from "./domain/auth/blocked.guard";
import { superAdminRoutes } from "./feature/super-admin/super-admin.routes";
import { consentRejectedGiven } from "./domain/legal/consent-rejected.guard";
import { profileRoutes } from "./feature/profile/profile.routes";
import { settingsRoutes } from "./feature/settings/settings.routes";
import { scopesGuard } from "./domain/auth/scope.guard";
import { Scopes } from "@libs/shares/models/auth.model";
import { kycGuard } from "./domain/auth/kyc.guard";
import { isPaymentOwnerGuard } from "./domain/auth/is-payment-owner.guard";
import { surveyGuard } from "./domain/auth/survey.guard";

export const appRoutes: Route[] = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "",
    loadComponent: () => import("./feature/layout/base-layout.component"),
    children: [
      {
        path: "login",
        loadComponent: () => import("./feature/login/login.component"),
        canActivate: [loginGuard],
        title: "login",
        data: { basicNavigation: true, title: "login" }
      },
      {
        path: "login/:orgId",
        loadComponent: () => import("./feature/login/login.component"),
        canActivate: [loginGuard],
        title: "login",
        data: { basicNavigation: true, title: "login sso" }
      },
      {
        path: "consent",
        canActivate: [consentGiven],
        title: "consent",
        data: { basicNavigation: true },
        loadComponent: () => import("./feature/consent/consent-management.component")
      },
      {
        path: "verification",
        title: "verification",
        canActivate: [kycGuard],
        data: { basicNavigation: true },
        loadComponent: () => import("./feature/verification/verification.component")
      },

      {
        path: "logout",
        loadComponent: () => import("./feature/logout/logout.component")
      },
      {
        path: "profile-setup",
        title: "create-first-app",
        loadComponent: () => import("./feature/profile-set-up/profile-set-up.component"),
        canActivate: [profileSetupGuard, isSuspended],
        data: { basicNavigation: true }
      },
      {
        path: "support",
        title: "support",
        loadComponent: () => import("./feature/support/support.component")
      },
      {
        path: "documentation",
        title: "documentation",
        children: [
          {
            path: "**",
            title: "documentation",
            loadComponent: () => import("./feature/documentation/documentation.component")
          },
          {
            path: "swagger-item",
            title: "documentation",
            loadComponent: () => import("./feature/documentation/swagger/request-description.component")
          }
        ]
      },
      {
        path: "registration-blocked",
        title: "registration-blocked",
        data: {
          translationKeyBody: "registrationBlocked.body",
          translationKeyTitle: "registrationBlocked.title"
        },
        loadComponent: () => import("./feature/suspended-account/suspended-account.component")
      },
      {
        path: "pricing",
        loadComponent: () => import("./feature/pricing/pricing.component"),
        title: "pricing"
      },
      {
        path: "survey/:formId",
        title: "survey",
        data: { basicNavigation: true },
        canActivate: [surveyGuard],
        loadComponent: () => import("./feature/survey/survey.component")
      }
    ]
  },
  {
    path: "",
    loadComponent: () => import("./feature/layout/base-layout.component"),
    canActivateChild: [canAccess, isSuspended],
    children: [
      {
        path: "applications",
        children: [
          {
            path: "",
            loadComponent: () => import("./feature/applications/applications.component"),
            title: "application-list"
          },
          {
            path: ":applicationId",
            loadComponent: () => import("./feature/applications/application-details.component"),
            title: "application-details"
          }
        ]
      },
      {
        path: "statistics",
        loadComponent: () => import("./feature/stats/stats.component"),
        title: "statistics"
      },
      {
        path: "dashboard",
        loadComponent: () => import("./feature/dashboard/dashboard.component"),
        title: "dashboard"
      },
      {
        path: "change-plan",
        loadComponent: () => import("./feature/upgrade-plan/upgrade-plan.component"),
        title: "change-plan"
      },
      {
        path: "topup-plan",
        loadComponent: () => import("./feature/topup/topup-plan.component"),
        title: "topup-plan"
      },
      {
        path: "manage-subscriptions",
        loadComponent: () => import("./feature/manage-subscriptions/manage-subscriptions.component"),
        children: [
          {
            path: "subscriptions",
            children: [
              {
                path: ":subscriptionId",
                loadComponent: () =>
                  import("./feature/manage-subscriptions/subscription-details/manage-subscription.component"),
                title: "manage-subscription"
              },
              {
                path: "",
                loadComponent: () =>
                  import("./feature/manage-subscriptions/subscription-list/subscription-list.component"),
                title: "manage-subscriptions"
              }
            ]
          },
          {
            path: "invoices",
            loadComponent: () => import("./feature/manage-subscriptions/invoices/subscriptions-invoices.component"),
            title: "subscriptions-invoices"
          },
          {
            path: "",
            redirectTo: "subscriptions",
            pathMatch: "full"
          }
        ]
      },
      {
        path: "payment/:paymentId",
        canActivate: [scopesGuard([Scopes.PaymentWrite]), isPaymentOwnerGuard],
        loadComponent: () => import("./feature/upgrade-plan/standalone-payment.component"),
        title: "payment"
      },
      {
        path: "modify-consent",
        title: "consent",
        canActivate: [consentRejectedGiven],
        data: { basicNavigation: true },
        loadComponent: () => import("./feature/consent/consent-management.component")
      },
      ...profileRoutes,
      ...settingsRoutes
    ]
  },
  ...superAdminRoutes,
  {
    path: "",
    loadComponent: () => import("./feature/layout/base-layout.component"),
    data: { skipBlockRedirect: true },
    children: [
      {
        path: "suspended-acccount",
        title: "suspended-acccount",
        loadComponent: () => import("./feature/suspended-account/suspended-account.component"),
        data: {
          translationKeyBody: "accountSuspended.body",
          translationKeyTitle: "accountSuspended.title"
        }
      }
    ]
  },
  { path: "enterprise", redirectTo: "pricing", pathMatch: "full" },
  { path: "**", redirectTo: "dashboard", pathMatch: "full" }
];
